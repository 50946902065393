body {
  background-color: #f9f9f9;
}

.MapContainer {
  border-top: 1px solid #eaeaea;
  padding-top: 15px;
  margin-top: 15px;
}

.InfoHeading {
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
}

.Warning {
  background-color: pink;
  padding: 8px;
}

.InfoText {
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.App {
  text-align: left;
}

.App-logo {
  margin-top: 5vmin;
  pointer-events: none;
  max-width: 230px;
  width: 100%;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-create-container {
  width: 80vmin;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5vmin;
  margin-bottom: 20px;
  min-height: 40vh;
  padding: 25px;
}

.App-contents {
  padding: 40px 0px 20px 0px;
}

.Buttons {
  padding-top: 15px;
  border-top: 1px solid #eaeaea;
  margin-top: 30px;
}

a {
  color: orangered;
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}

h2 {
  font-size: 120%;
  margin-bottom: 8px;
}

h2.spaceAbove {
  margin-top: 30px;
}